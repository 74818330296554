// import main.less to compile less
import './vendor.less';

// polyfills
import 'core-js/features/object/assign';
import 'core-js/features/dom-collections';
// import 'scopedQuerySelectorShim/dist/scopedQuerySelectorShim';
import 'picturefill';
import objectFitImages = require('object-fit-images');

import 'formdata-polyfill';

document.addEventListener('DOMContentLoaded', (e) => {
    objectFitImages(null);
})

import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();
